


































































import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";
import Screen from "@/app/modules/_global/classes/Screen";

@Component
export default class HomePage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("Home");
  }

  boundedContentHeight = 0;

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get whiteboardMenuItem() {
    return this.appData.nav_menu_items.find(_ => _.route == "posts");
  }

  beforeDestroy() {
    this.scrollTop();
  }

  created() {
    this.$nextTick(() => {
      const screen = new Screen();
      this.boundedContentHeight = screen.getBoundedContentHeight();
    });
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
