import Device from "@/app/modules/_global/classes/Device";

// https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/

const IPHONE_XS = { width: 375, height: 812 }; // Includes: X, Xs, 11 Pro
const IPHONE_XR = { width: 414, height: 896 }; // Includes: Xr, Xs Max, 11, 11 Pro Max
const IPHONE_12 = { width: 428, height: 926 }; // Includes: 12 Pro Max

export default class Screen {
  private _header: HTMLCollectionOf<HTMLElement>;
  private _footer: HTMLCollectionOf<HTMLElement>;

  private _headerOffsetHeight: number;
  private _footerOffsetHeight: number;

  private _deviceName?: string;

  constructor() {
    this._header = document.getElementsByTagName("header");
    this._footer = document.getElementsByTagName("footer");

    this._headerOffsetHeight = this._header[0].offsetHeight;
    this._footerOffsetHeight = this._footer[0].offsetHeight;
  }

  getBoundedContentHeight(): number {
    let offset = 48; // The 48 number is connected with Vuetify's 12px padding

    if (this._screenSizeIs(IPHONE_XS)) offset = 84;
    if (this._screenSizeIs(IPHONE_XR)) offset = 84;
    if (this._screenSizeIs(IPHONE_12)) offset = 84;

    return this._headerOffsetHeight + this._footerOffsetHeight + offset;
  }

  getBoundedSidebarHeight(): number {
    if (this._screenSizeIs(IPHONE_XS)) return 54;
    if (this._screenSizeIs(IPHONE_XR)) return 54;
    if (this._screenSizeIs(IPHONE_12)) return 54;

    return 24; // Default padding
  }

  private _screenSizeIs(constantDevice: object): boolean {
    const device = new Device();

    return JSON.stringify(constantDevice) == JSON.stringify(device.getDeviceScreenSize);
  }
}
