var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-1",attrs:{"dense":""}},[(_vm.whiteboardMenuItem)?_c('v-col',{attrs:{"cols":"12","hidden":""}},[_c('v-btn',{staticClass:"buttonsFontColor--text text-none rounded-lg _btn-shadow",attrs:{"color":"buttonsColor","height":"56","block":""},on:{"click":function($event){return _vm.$router.push({ name: _vm.whiteboardMenuItem.route })}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.whiteboardMenuItem.icon)}}),_vm._v(" "+_vm._s(_vm.whiteboardMenuItem.name)+" ")],1)],1):_vm._e(),_vm._l((_vm.appData.tiles),function(tile,i){return _c('v-col',{key:i,attrs:{"cols":_vm.appData.tiles.length <= 3 ? 12 : 6}},[_c('v-card',{staticClass:"d-flex flex-column justify-center align-center px-2 rounded-lg",style:(("box-shadow: 0 4px 8px -4px " + (tile.type.name != 'Notruf'
            ? 'var(--v-tilesColor-base)'
            : 'var(--v-notrufTileColor-base)') + ";")),attrs:{"color":tile.type.name != 'Notruf' ? 'tilesColor' : 'notrufTileColor',"height":"160","to":{
        name: 'tile',
        params: { tile: tile.id }
      }}},[(tile.default_icon)?_c('v-icon',{staticClass:"pa-2",style:(("text-shadow: 0 2px 4px " + (tile.type.name != 'Notruf'
              ? 'var(--v-tilesColor-darken1)'
              : 'var(--v-notrufTileColor-darken1)') + ";")),attrs:{"color":tile.type.name != 'Notruf' ? 'tilesIconColor ' : 'notrufTileIconColor',"size":"80"},domProps:{"textContent":_vm._s(tile.default_icon)}}):_c('img',{staticClass:"mb-3",attrs:{"src":tile.icon.url,"height":"80"}}),_c('h3',{staticClass:"font-weight-medium text-center px-4",class:tile.type.name != 'Notruf' ? 'tilesFontColor--text ' : 'notrufTileFontColor--text',style:(("text-shadow: 0 1px 2px " + (tile.type.name != 'Notruf'
              ? 'var(--v-tilesColor-darken2)'
              : 'var(--v-notrufTileColor-darken2)') + "; hyphens: auto; line-height: 1em;")),domProps:{"innerHTML":_vm._s(tile.name)}})],1)],1)}),_c('v-col',{staticClass:"pa-0 _safeAreaInsetPaddingBottom",attrs:{"cols":"12"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }